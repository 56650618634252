import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { INSTANCE_CODE } from '@konnektu/tokens';
import { Observable, catchError, map, of } from 'rxjs';
import {
  MarketingCampaignCreateRequestDto,
  MarketingCampaignDetailDto,
  MarketingCampaignEditRequestDto,
  MarketingCampaignListItemDto,
  MarketingCampaignProcessListItemDto,
  MarketingCampaignStartProcessResponseDto
} from './models';

@Injectable({ providedIn: 'root' })
export class MarketingCampaignService {
  private readonly http = inject(HttpClient);

  private readonly tenantCode = inject(INSTANCE_CODE);

  getMarketingCampaigns(limit: number = 50, offset: number = 0) {
    return this.http.get<MarketingCampaignListItemDto[]>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns?limit=${limit}&offset=${offset}`
    );
  }

  getMarketingCampaign(id: number) {
    return this.http.get<MarketingCampaignDetailDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${id}`
    );
  }

  getMarketingCampaignProcess(processId: number) {
    return this.http.get<MarketingCampaignProcessListItemDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/processes/${processId}`
    );
  }

  createMarketingCampaign(request: MarketingCampaignCreateRequestDto) {
    return this.http.post<MarketingCampaignDetailDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns`,
      request
    );
  }

  updateMarketingCampaign(
    id: number,
    request: MarketingCampaignEditRequestDto
  ) {
    return this.http.patch<MarketingCampaignDetailDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${id}`,
      request
    );
  }

  deleteMarketingCampaign(id: number) {
    return this.http.delete(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${id}`
    );
  }

  copyMarketingCampaign(id: number, suffix?: string) {
    return this.http.post<MarketingCampaignDetailDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${id}/copies`,
      {
        suffix
      }
    );
  }

  getMarketingCampaignProcesses(marketingCampaignId: number) {
    return this.http
      .get<
        MarketingCampaignProcessListItemDto[]
      >(`${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${marketingCampaignId}/processes`)
      .pipe(
        map((processList) =>
          processList.sort(
            (a, b) =>
              new Date(b.startedAt).getTime() - new Date(a.startedAt).getTime()
          )
        )
      );
  }

  startMarketingCampaignProcess(id: number) {
    return this.http.post<MarketingCampaignStartProcessResponseDto>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${id}/processes`,
      null
    );
  }

  stopMarketingCampaignProcess(processId: number) {
    return this.http.post<void>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/processes/${processId}`,
      null
    );
  }

  checkNameUniqueness(name: string): Observable<boolean> {
    return this.http
      .get<void>(
        `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/names/${encodeURIComponent(name)}`,
        { observe: 'response' }
      )
      .pipe(
        map((response) => response.status === 404),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            return of(true);
          }
          return of(false);
        })
      );
  }

  restartActivity(
    marketingCampaignId: number,
    processId: number,
    activityId: string,
    logId: number
  ) {
    return this.http.post<void>(
      `${this.tenantCode}/api/uzilla/v1/marketing-campaigns/${marketingCampaignId}/processes/${processId}/activities/restarts`,
      { activityId, logId }
    );
  }
}
